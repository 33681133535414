import {
  IspTaskFile,
  JoinApplicationFile,
  JoinApplicationFileLabels,
  UisFileType,
  UserProfileFile,
} from '@uis-enums/file-types';
import { bytesToDisplayString, mbToBytes } from '@uis-core/helpers/utils';
import { UisFile } from '@uis-models/contract/uis-file';

const DEFAULT_IMAGE_FILE_SIZE = mbToBytes(1);
const DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE = mbToBytes(10);
const INDEPENDENT_WORK_RESOURCE_TOTAL_FILE_SIZE = mbToBytes(50);
const DEFAULT_VIDEO_FILE_SIZE = mbToBytes(50);
const DEFAULT_AUDIO_FILE_SIZE = mbToBytes(50);

export enum AllowedImageMimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export enum AllowedDocumentMimeType {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLSM = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TXT = 'text/plain',
  XML = 'application/xml',
  ODT = 'application/vnd.oasis.opendocument.text',
  ODF = 'application/vnd.oasis.opendocument.formula',
}

export enum AllowedVideoMimeType {
  MP4 = 'video/mp4',
  MOV = 'video/quicktime',
  AVI = 'video/x-msvideo',
}

export enum AllowedAudioMimeType {
  MP3 = 'audio/mpeg',
}

export type AllowedFileMimeType =
  | AllowedDocumentMimeType
  | AllowedAudioMimeType
  | AllowedVideoMimeType
  | AllowedImageMimeType;

export const displayedMimeTypeExtensions: {
  [key in AllowedFileMimeType]: string[];
} = {
  [AllowedImageMimeType.JPEG]: ['jpeg'],
  [AllowedImageMimeType.PNG]: ['png'],
  [AllowedDocumentMimeType.PDF]: ['pdf'],
  [AllowedDocumentMimeType.DOC]: ['doc'],
  [AllowedDocumentMimeType.DOCX]: ['docx'],
  [AllowedDocumentMimeType.XLS]: ['xls'],
  [AllowedDocumentMimeType.XLSX]: ['xlsx'],
  [AllowedDocumentMimeType.XLSM]: ['xlsm'],
  [AllowedDocumentMimeType.PPT]: ['ppt'],
  [AllowedDocumentMimeType.PPTX]: ['pptx'],
  [AllowedDocumentMimeType.TXT]: ['txt'],
  [AllowedDocumentMimeType.XML]: ['xml'],
  [AllowedDocumentMimeType.ODT]: ['odt'],
  [AllowedDocumentMimeType.ODF]: ['odf'],
  [AllowedVideoMimeType.MP4]: ['mp4'],
  [AllowedVideoMimeType.MOV]: ['mov'],
  [AllowedVideoMimeType.AVI]: ['avi'],
  [AllowedAudioMimeType.MP3]: ['mp3'],
};

export const DefaultFileMimeTypes: Record<
  string,
  readonly AllowedFileMimeType[]
> = {
  Excel: [
    AllowedDocumentMimeType.XLS,
    AllowedDocumentMimeType.XLSM,
    AllowedDocumentMimeType.XLSX,
  ],
  Word: [
    AllowedDocumentMimeType.DOC,
    AllowedDocumentMimeType.DOCX,
    AllowedDocumentMimeType.ODT,
  ],
  PowerPoint: [AllowedDocumentMimeType.PPT, AllowedDocumentMimeType.PPTX],
  Image: Object.values(AllowedImageMimeType),
  Video: Object.values(AllowedVideoMimeType),
  Audio: Object.values(AllowedAudioMimeType),
  Document: [
    AllowedImageMimeType.JPEG,
    AllowedImageMimeType.PNG,
    AllowedDocumentMimeType.PDF,
    AllowedDocumentMimeType.PPTX
  ],
} as const;

export const UisFileTypeSettingsRegistry: {
  [key in UisFileType]: UisFileTypeSettings<key>;
} = {
  // Zero in number value is treated as "No limit"
  any: {
    type: 'any',
    displayName: 'Файл',
    maxFiles: 0,
    maxTotalSize: 0,
    maxFileSize: 0,
    allowedMimeTypes: [],
  },
  [UserProfileFile.ProfilePicture]: {
    type: UserProfileFile.ProfilePicture,
    displayName: 'Фото користувача',
    tooltip: 'Це зображення бачитимуть інші користувачі НІТ Євроленд',
    maxTotalSize: 0,
    maxFiles: 1,
    maxFileSize: mbToBytes(2),
    allowedMimeTypes: [AllowedImageMimeType.JPEG],
  },

  [JoinApplicationFile.AuthorizedPersonsStatement]: {
    type: JoinApplicationFile.AuthorizedPersonsStatement,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.AuthorizedPersonsStatement],
    tooltip:
      'Щоб стати екстерном Державного ліцею “Міжнародна українська школа” (далі – ліцею) необхідно подати Заяву на зарахування (для неповнолітніх – за заявою одного з батьків або осіб, які їх замінюють)',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.IdentityDocument]: {
    type: JoinApplicationFile.IdentityDocument,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.IdentityDocument],
    tooltip:
      'Копія або сканована копія документа, що засвідчує особу (свідоцтво про народження/паспорт), ID-картка (без перекладу)',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.ResidenceDocument]: {
    type: JoinApplicationFile.ResidenceDocument,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.ResidenceDocument],
    tooltip:
      'Резиденція, довідка з місця роботи батьків, витяг з контракту, консульський облік (тимчасовий чи постійний), довідка з місця навчання у навчальному закладі країни перебування, освітньому об’єднанні тощо',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.StudentPersonalFile]: {
    type: JoinApplicationFile.StudentPersonalFile,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.StudentPersonalFile],
    tooltip:
      "Документ, що містить особисті дані учня, такі як повне ім'я, дата народження, адреса, дані батьків або опікунів, інформація про навчання та академічні досягнення, а також будь-яка інша важлива інформація, пов'язана з учнем",
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.BasicEducationCertificate]: {
    type: JoinApplicationFile.BasicEducationCertificate,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.BasicEducationCertificate],
    tooltip:
      'Документ, який підтверджує, що особа успішно завершила базовий рівень освіти, який може бути відомий під різними назвами в різних країнах, наприклад, сертифікат про початкову освіту, атестат про базову загальну освіту тощо',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.FirstSemFinalMarks]: {
    type: JoinApplicationFile.FirstSemFinalMarks,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.FirstSemFinalMarks],
    tooltip:
      'Документ державного зразка, який містить записи про академічні досягнення учня протягом певного періоду',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [JoinApplicationFile.AcademicReference]: {
    type: JoinApplicationFile.AcademicReference,
    displayName:
      JoinApplicationFileLabels[JoinApplicationFile.AcademicReference],
    tooltip:
      "Офіційний документ, який містить інформацію про академічний статус та досягнення студента або учня. Зазвичай містить таку інформацію, як назва закладу освіти, дата видачі, повне ім'я студента або учня, курс або клас, який вони відвідують, список предметів, які вони вивчають або вивчали, оцінки за кожен предмет, відвідуваність та інші академічні дані",
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [IspTaskFile.Assignment]: {
    type: IspTaskFile.Assignment,
    displayName: 'Завдання',
    tooltip: '',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [IspTaskFile.IndependentWorkResource]: {
    type: IspTaskFile.IndependentWorkResource,
    displayName: 'Завдання',
    tooltip: '',
    maxTotalSize: INDEPENDENT_WORK_RESOURCE_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [IspTaskFile.Answer]: {
    type: IspTaskFile.Answer,
    displayName: 'Відповідь',
    tooltip: '',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
  [IspTaskFile.ReviewedAnswer]: {
    type: IspTaskFile.ReviewedAnswer,
    displayName: 'Перевірена відповідь',
    tooltip: '',
    maxTotalSize: DEFAULT_DOCUMENTS_TOTAL_FILE_SIZE,
    maxFiles: 0,
    maxFileSize: 0,
    allowedMimeTypes: DefaultFileMimeTypes['Document'],
  },
};

export type UisFileTypeSettings<Type> = {
  type: Type;
  displayName: string;
  tooltip?: string;
  maxTotalSize: number;
  maxFileSize: number;
  maxFiles: number;
  allowedMimeTypes: readonly AllowedFileMimeType[];
};

export function getMimeTypeExtensionString(
  allowedMimeType: AllowedFileMimeType,
) {
  return displayedMimeTypeExtensions[allowedMimeType]
    .map((ext) => `${ext}`)
    .join(', ');
}

export function getFileTypeError(
  files: UisFile[],
  fileType: UisFileType,
): string {
  const fileTypeSettings = UisFileTypeSettingsRegistry[fileType];
  const testedArray = Array.isArray(files) ? files : [files];
  if (
    fileTypeSettings.maxFiles &&
    testedArray.length > fileTypeSettings.maxFiles
  ) {
    return `Максимум файлів ${fileTypeSettings.maxFiles}`;
  }

  if (
    fileTypeSettings.maxTotalSize &&
    testedArray.reduce((total, file) => total + file.fileSize, 0) >
      fileTypeSettings.maxTotalSize
  ) {
    return `Загальний об'єм файлів має бути менше ніж ${bytesToDisplayString(
      fileTypeSettings.maxTotalSize,
    )}`;
  }

  if (
    fileTypeSettings.maxFileSize &&
    testedArray.some((file) => file.fileSize > fileTypeSettings.maxFileSize)
  ) {
    return `Максимальний розмір окремого файлу ${bytesToDisplayString(
      fileTypeSettings.maxFileSize,
    )}`;
  }

  if (
    fileTypeSettings.allowedMimeTypes.length &&
    testedArray.some(
      (file) => !fileTypeSettings.allowedMimeTypes.includes(file.contentType),
    )
  ) {
    return `Дозволені типи файлів:  ${fileTypeSettings.allowedMimeTypes
      .map((mimeType) => getMimeTypeExtensionString(mimeType))
      .join(', ')}`;
  }

  return '';
}
