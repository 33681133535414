import {
  assertInInjectionContext,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Signal,
  signal,
  untracked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIS_DIALOG_DATA } from '@uis-common/dialog-container/dialog-container.component';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { UisValidators } from '@uis-core/validators/validators';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { UisFormArray } from '@uis-core/forms/uis-form-array';
import { ButtonComponent } from '@uis-common/button/button.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  getMarkText,
  IndividualStudyPlanItem,
  IndividualStudyPlanItemAssignmentVariant,
  IndividualStudyPlanItemRejectRequest,
  IndividualStudyPlanItemTask,
  IndividualStudyPlanItemTaskType,
  IndividualStudyPlanItemTaskTypeText,
  IndividualStudyPlanItemType,
  IndividualStudyPlanItemTypeText,
  IndividualStudyPlanSubjectDataScheduleType,
  LeveledMarkValue,
  MarkType,
  NumericMarkValue,
  UisMark,
  VerbalMarkValue,
} from '@uis-models/contract/individual-study-plan';
import { DatepickerComponent } from '@uis-common/inputs/datepicker/datepicker.component';
import { MatInputModule } from '@angular/material/input';
import { FileInputComponent } from '@uis-common/inputs/file-inputs/file-input/file-input.component';
import { IndividualStudyPlanService } from '@uis-services/individual-study-plan/individual-study-plan.service';
import { MessageService } from '@uis-services/message/message.service';
import {
  BehaviorSubject,
  defer,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ContentDividerComponent } from '@uis-common/content-divider/content-divider.component';
import { ControlErrorContainerComponent } from '@uis-common/inputs/infrastrucure/control-error-container/control-error-container.component';
import { IspTaskFile } from '@uis-enums/file-types';
import {
  InfoTableComponent,
  InfoTableItem,
} from '@uis-common/info-table/info-table.component';
import {
  IspDialogContext,
  IspTaskDialogAction,
} from '@uis-private/individual-study-plan/dialogs/isp-dialog.types';
import { DialogService } from '@uis-services/dialog/dialog.service';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { DisableControlDirective } from '@uis-directives/disable-control/disable-control.directive';
import { SearchResult } from '@uis-models/contract/search';
import { getRandomElement } from '@uis-core/helpers/utils';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { IspInfoBoxComponent } from '@uis-common/info-box/isp-info-box.component';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  imports: [
    CommonModule,
    ButtonComponent,
    MatDialogModule,
    ReactiveFormsModule,
    FormFieldComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    DatepickerComponent,
    MatInputModule,
    FileInputComponent,
    ContentDividerComponent,
    ControlErrorContainerComponent,
    InfoTableComponent,
    DisableControlDirective,
    MatProgressSpinner,
    IspInfoBoxComponent,
    MatCheckbox,
  ],
  templateUrl: './isp-item-form-dialog.component.html',
  styleUrl: '../../../../../common/dialog.common.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IspItemFormDialogComponent {
  protected readonly IndividualStudyPlanItemTaskType =
    IndividualStudyPlanItemTaskType;
  protected readonly IspTaskFile = IspTaskFile;
  protected readonly IndividualStudyPlanItemTaskTypeText =
    IndividualStudyPlanItemTaskTypeText;
  private readonly dialogRef = inject(MatDialogRef);
  private readonly dialogService = inject(DialogService);
  private readonly ispService = inject(IndividualStudyPlanService);
  private readonly message = inject(MessageService);
  protected readonly can = inject(RoleDataService).can;
  protected readonly dialogData = signal(
    inject<IspDialogContext>(UIS_DIALOG_DATA),
  ).asReadonly();
  protected readonly createItemParams = computed(
    () => this.dialogData().createItem ?? null,
  );
  protected readonly item = computed(() => this.dialogData().item ?? null);
  protected readonly task = computed(() => {
    const item = this.item();
    const taskType = this.dialogData().taskType;
    if (!item || !item.tasks.length) {
      return null;
    }

    return item.tasks.find((task) => task.type === taskType) ?? null;
  });
  protected readonly calendarStart = new Date();
  protected readonly calendarEnd =
    this.dialogData().individualStudyPlan.academicYear.end;
  private readonly individualStudyPlan = computed(
    () => this.dialogData().individualStudyPlan,
  );
  protected readonly isPreviousDialog =
    this.dialogService.openDialogs.length > 1;
  protected readonly markType = computed(() => {
    const individualStudyPlan = this.individualStudyPlan();
    const classLevel = individualStudyPlan.class.classLevel;

    let markType = MarkType.Numeric;

    if (classLevel <= 2) {
      markType = MarkType.Verbal;
    }
    if (classLevel >= 3 && classLevel <= 4) {
      markType = MarkType.Leveled;
    }
    if (classLevel >= 5) {
      markType = MarkType.Numeric;
    }

    return markType;
  });
  protected readonly markOptions = computed<
    { value: UisMark['value']; label: string }[]
  >(() => {
    const markType = this.markType();
    let markValues: any = NumericMarkValue;

    switch (markType) {
      case MarkType.Verbal: {
        markValues = VerbalMarkValue;
        break;
      }
      case MarkType.Leveled:
        markValues = LeveledMarkValue;
        break;
      case MarkType.Numeric:
        markValues = NumericMarkValue;
        break;
    }

    return Object.values(markValues)
      .map((value) => ({
        type: markType!,
        value: value as UisMark['value'],
      }))
      .map((mark) => ({
        label: getMarkText(mark as any).full,
        value: mark.value,
      }));
  });
  protected readonly itemAssignmentVariantsCompareWith = (
    option: IndividualStudyPlanItemAssignmentVariant | null,
    selection: IndividualStudyPlanItemAssignmentVariant | null,
  ) => !!(option && selection && option.id === selection.id);

  protected readonly ispItemForm = new UisFormGroup({
    id: new FormControl(null),
    type: new FormControl(null, [UisValidators.required()]),
    individualStudyPlanId: new FormControl(null, [UisValidators.required()]),
    subjectData: new UisFormGroup({
      subject: new FormControl(null, [UisValidators.required()]),
      teacher: new FormControl(null, [UisValidators.required()]),
    }),
    currentAssignmentVariantId: new FormControl(null),
    rejectedAssignmentVariantsIds: new FormControl(null),
    tasks: new UisFormArray(
      [
        this.getTaskFormGroup(
          IndividualStudyPlanItemTaskType.WrittenAssignment,
        ),
        this.getTaskFormGroup(IndividualStudyPlanItemTaskType.Interview),
      ],
      () => this.getTaskFormGroup(),
    ),
    rejectReason: new FormControl(null, [
      UisValidators.requiredIf(
        () => this.dialogData().action === IspTaskDialogAction.Reject,
      ),
    ]),
  });

  protected readonly assessmentForm = new UisFormGroup({
    itemId: new FormControl(this.item()?.id ?? null, [
      UisValidators.required(),
    ]),
    taskType: new FormControl(this.task()?.type ?? null, [
      UisValidators.required(),
    ]),
    mark: new UisFormGroup({
      type: new FormControl(this.markType(), [UisValidators.required()]),
      value: new FormControl(this.task()?.assessmentResult?.mark.value, [
        UisValidators.required(),
      ]),
      comment: new FormControl(this.task()?.assessmentResult?.mark.comment, [
        UisValidators.maxLength(1000),
      ]),
    }),
    reviewedAnswer: new UisFormGroup({
      attachments: new FormControl(
        this.task()?.assessmentResult?.reviewedAnswer?.attachments ?? [],
        [UisValidators.validateFileType(IspTaskFile.ReviewedAnswer)],
      ),
      description: new FormControl(
        this.task()?.assessmentResult?.reviewedAnswer?.description,
        [UisValidators.maxLength(1000)],
      ),
    }),
  });

  protected readonly submitAnswerForm = new UisFormGroup({
    itemId: new FormControl(this.item()?.id ?? null, [
      UisValidators.required(),
    ]),
    taskType: new FormControl(this.task()?.type ?? null, [
      UisValidators.required(),
    ]),
    answer: new UisFormGroup(
      {
        attachments: new FormControl(
          [],
          [UisValidators.validateFileType(IspTaskFile.Answer)],
        ),
        description: new FormControl('', [UisValidators.maxLength(1000)]),
      },
      [
        UisValidators.atLeastOneControlValid(
          UisValidators.required(),
          'Maє бути хоча б щось одне',
        ),
      ],
    ),
  });

  protected readonly correctionRequestForm = new UisFormGroup({
    itemId: new FormControl(this.item()?.id ?? null, [
      UisValidators.required(),
    ]),
    taskType: new FormControl(this.task()?.type ?? null, [
      UisValidators.required(),
    ]),
    correctionReason: new FormControl(null, [
      UisValidators.required(),
      UisValidators.maxLength(1000),
    ]),
  });

  protected readonly interviewTimeForm = new UisFormGroup({
    itemId: new FormControl(this.item()?.id ?? null, [
      UisValidators.required(),
    ]),
    date: new FormControl(this.task()?.date ?? null, [
      UisValidators.required(),
      UisValidators.dateInTheFuture(),
    ]),
  });

  protected readonly ispItemFormValue = toSignal(
    this.ispItemForm.valueChanges,
    { initialValue: this.ispItemForm.value },
  );

  protected generalInfoItems = computed<InfoTableItem[]>(() => {
    const ispItemFormValue = this.ispItemFormValue();
    const individualStudyPlan = this.individualStudyPlan();

    if (!ispItemFormValue) {
      return [];
    }

    return [
      {
        label: 'Предмет',
        displayedValue: (ispItemFormValue.subjectData?.subject as any)
          ?.name as string,
      },
      {
        label: 'Вчитель',
        displayedValue: (ispItemFormValue.subjectData?.teacher as any)
          ?.fullName as string,
      },
      {
        label: 'Учень',
        displayedValue: individualStudyPlan.student.fullName,
      },
      {
        label: 'Клас',
        displayedValue: `${individualStudyPlan.class.classLevel}-й`,
      },
      {
        label: 'Тип',
        displayedValue: IndividualStudyPlanItemTypeText[
          (ispItemFormValue.type as any as IndividualStudyPlanItemType)!
        ] as string,
      },
    ];
  });

  protected readonly isTasks = computed(() => {
    const dialogData = this.dialogData();
    return (
      !dialogData.action ||
      dialogData.action === IspTaskDialogAction.Reject ||
      dialogData.action === IspTaskDialogAction.EditAssignment
    );
  });

  protected readonly itemAssignmentVariantsLoading$ = new BehaviorSubject(
    false,
  );
  protected readonly itemAssignmentVariants: Signal<
    IndividualStudyPlanItemAssignmentVariant[]
  > = toSignal(
    toObservable(this.isTasks).pipe(
      delay(1), // So that form value is already set
      switchMap((isTasks) =>
        isTasks && this.ispItemForm.value.type
          ? defer(() => {
              this.itemAssignmentVariantsLoading$.next(true);
              const rejectedAssignmentVariantsIds =
                this.item()?.rejectedAssignmentVariantsIds ?? [];
              if (
                this.dialogData().action === IspTaskDialogAction.Reject &&
                this.item()?.currentAssignmentVariantId
              ) {
                rejectedAssignmentVariantsIds.push(
                  this.item()?.currentAssignmentVariantId!,
                );
              }

              return this.ispService.getDefaultAssignmentVariantsForItem(
                this.individualStudyPlan().class.classLevel,
                {
                  ...this.ispItemForm.value,
                  rejectedAssignmentVariantsIds,
                } as any,
              );
            }).pipe(
              finalize(() => this.itemAssignmentVariantsLoading$.next(false)),
            )
          : of(new SearchResult<IndividualStudyPlanItemAssignmentVariant>()),
      ),
      map((res) => res.data),
      tap((variants) => {
        if (variants.length && this.createItemParams()) {
          this.ispItemForm.controls.currentAssignmentVariantId.patchValue(
            getRandomElement(variants)!.id as any,
          );
        }
      }),
    ),
    { initialValue: [] as IndividualStudyPlanItemAssignmentVariant[] },
  );

  protected readonly isDeleteButton = computed(() => {
    const isEditMode = this.dialogData().isEditMode;
    const deletePermission = this.can.Delete.IndividualStudyPlanItem.Own();
    const item = this.item();

    const allTasksCanBeDeleted = item?.tasks.every((task) =>
      this.dialogData().tasksCanBeEdited?.includes(task.type),
    );

    return isEditMode && deletePermission && item && allTasksCanBeDeleted;
  });

  private readonly formPatchEffect = effect(
    () => {
      const createItemParams = this.createItemParams();
      const item = this.dialogData().item;

      if (!createItemParams) {
        if (item) {
          const { currentAssignmentVariantId, tasks, ...itemRest } = item;
          this.ispItemForm.patchValue(
            (this.dialogData().action === IspTaskDialogAction.Reject
              ? itemRest
              : item) as any,
            {
              initArrays: false,
            },
          );
        }
      } else {
        let createdType: IndividualStudyPlanItemType =
          IndividualStudyPlanItemType.EndOfYearEvaluation;

        switch (createItemParams.tableSubjectData.scheduleType) {
          case IndividualStudyPlanSubjectDataScheduleType.ByYear:
            createdType = IndividualStudyPlanItemType.EndOfYearEvaluation;
            break;
          case IndividualStudyPlanSubjectDataScheduleType.BySemester: {
            createdType = createItemParams.tableSubjectData.items.find(
              (item) =>
                item.type ===
                IndividualStudyPlanItemType.FirstSemesterEvaluation,
            )
              ? IndividualStudyPlanItemType.SecondSemesterEvaluation
              : IndividualStudyPlanItemType.FirstSemesterEvaluation;
          }
        }

        this.ispItemForm.controls.individualStudyPlanId.patchValue(
          this.individualStudyPlan().id as any,
        );
        this.ispItemForm.controls.type.patchValue(createdType as any);
        this.ispItemForm.controls.subjectData.patchValue(
          createItemParams.tableSubjectData as any,
        );

        const proposedDate = new Date();
        proposedDate.setFullYear(createItemParams.proposedMonthInfo.year);
        proposedDate.setMonth(createItemParams.proposedMonthInfo.month);

        if (
          createItemParams.proposedMonthInfo.year ===
            new Date().getFullYear() &&
          createItemParams.proposedMonthInfo.month === new Date().getMonth()
        ) {
          proposedDate.setDate(new Date().getDate() + 1);
        } else {
          proposedDate.setDate(1);
        }

        this.ispItemForm.controls.tasks.clear();
        this.ispItemForm.controls.tasks.push(
          this.getTaskFormGroup(
            IndividualStudyPlanItemTaskType.WrittenAssignment,
            proposedDate,
          ),
        );

        this.ispItemForm.controls.tasks.push(
          this.getTaskFormGroup(IndividualStudyPlanItemTaskType.Interview),
        );
      }
    },
    { allowSignalWrites: true },
  );

  private buildTaskFormGroupSignalByType(
    type: IndividualStudyPlanItemTaskType,
  ) {
    assertInInjectionContext(this.buildTaskFormGroupSignalByType);
    return toSignal<UisFormGroup<any> | null>(
      this.ispItemForm.valueChanges.pipe(
        startWith(this.ispItemForm.value),
        map(() => {
          const controlIndex = (
            this.ispItemForm.value.tasks as IndividualStudyPlanItemTask[]
          ).findIndex((task) => task.type === type);

          return controlIndex >= 0
            ? (this.ispItemForm.controls.tasks.at(
                controlIndex,
              ) as any as UisFormGroup<any>)
            : null;
        }),
        distinctUntilChanged(),
      ),
      { initialValue: null },
    );
  }

  private readonly writtenAssignmentFormGroup =
    this.buildTaskFormGroupSignalByType(
      IndividualStudyPlanItemTaskType.WrittenAssignment,
    );

  private readonly interviewFormGroup = this.buildTaskFormGroupSignalByType(
    IndividualStudyPlanItemTaskType.Interview,
  );

  private readonly writtenAssignmentDateControl = computed(() => {
    const control = this.writtenAssignmentFormGroup()?.controls['date'];

    return control ? (control as AbstractControl<Date | null>) : null;
  });
  private readonly interviewDateControl = computed(() => {
    const control = this.interviewFormGroup()?.controls['date'];

    return control ? (control as AbstractControl<Date | null>) : null;
  });

  private readonly writtenAssignmentControlValue = toSignal<Date | null>(
    toObservable(this.writtenAssignmentDateControl).pipe(
      switchMap(
        (control) =>
          control?.valueChanges.pipe(startWith(control?.value)) ?? of(null),
      ),
    ),
  );

  public isInterviewEnabled = signal(
    (this.dialogData().item?.tasks?.length as any) > 1,
  );

  private readonly interviewDateControlValueUpdateEffect = effect(
    () => {
      const writtenAssignmentDateControl = this.writtenAssignmentDateControl();
      const interviewDateControl = this.interviewDateControl();
      const writtenAssignmentControlValue =
        this.writtenAssignmentControlValue();

      if (
        !this.isTasks() ||
        !writtenAssignmentDateControl ||
        !(writtenAssignmentControlValue instanceof Date)
      ) {
        return;
      }
      const valueWithTime = new Date(writtenAssignmentControlValue);
      valueWithTime.setHours(23, 59, 59, 999);

      untracked(() => {
        writtenAssignmentDateControl.setValue(valueWithTime, {
          emitEvent: false,
        });
      });

      if (!interviewDateControl) {
        return;
      }

      const newValue = new Date(writtenAssignmentControlValue);
      newValue.setDate(newValue.getDate() + 7);
      interviewDateControl.setValue(newValue);
    },
    { allowSignalWrites: true },
  );

  protected readonly texts = computed<{
    title: string;
    submitButton: string;
    declineButton: string;
  }>(() => {
    const action = this.dialogData().action;
    const createItemParams = this.createItemParams();

    let title = 'Редагування роботи';
    let declineButton = this.isPreviousDialog
      ? 'Повернутись назад'
      : 'Скасувати';
    let submitButton = 'Зберегти зміни';

    if (!action) {
      if (createItemParams) {
        title = 'Створення роботи';
        submitButton = 'Створити';
      } else {
        title = 'Редагування роботи';
        submitButton = 'Зберегти зміни';
      }
    } else {
      switch (action) {
        case IspTaskDialogAction.SubmitAnswer:
          title = 'Надсилання відповіді';
          submitButton = 'Надіслати';
          break;
        case IspTaskDialogAction.Reject:
          title = 'Заміна роботи';
          submitButton = 'Замінити';
          break;
        case IspTaskDialogAction.RequestCorrection:
          title = 'Доопрацювання роботи';
          submitButton = 'Відправити';
          break;
        case IspTaskDialogAction.EditMark:
          title = 'Редагування оцінки';
          submitButton = 'Оцінити';
          break;
        case IspTaskDialogAction.Evaluate:
          title = 'Оцінювання завдання';
          submitButton = 'Оцінити';
          break;
        case IspTaskDialogAction.UpdateInterviewDate:
          title = 'Новий час співбесіди';
          submitButton = 'Встановити';
          break;
        case IspTaskDialogAction.EditAssignment:
          title = 'Редагування завдання';
          submitButton = 'Зберегти';
          break;
        default:
          title = 'Редагування роботи';
          break;
      }
    }

    return {
      title,
      submitButton,
      declineButton,
    };
  });

  private getTaskFormGroup(
    type?: IndividualStudyPlanItemTaskType,
    date?: Date,
  ) {
    return new UisFormGroup({
      type: new FormControl(type ?? null, [UisValidators.required()]),
      date: new FormControl(date ?? null, [UisValidators.required()]),
      assignment: new UisFormGroup({
        attachments: new FormControl(
          [],
          [UisValidators.validateFileType(IspTaskFile.Assignment)],
        ),
        description: new FormControl(null, [UisValidators.maxLength(1000)]),
      }),
    });
  }

  protected getAsTaskType(value: any) {
    return value as IndividualStudyPlanItemTaskType;
  }

  protected readonly currentVariantId = toSignal(
    this.ispItemForm.controls.currentAssignmentVariantId.valueChanges,
    {
      initialValue: this.ispItemForm.controls.currentAssignmentVariantId.value,
    },
  );

  private applyVariantToIspItemForm(
    variant: IndividualStudyPlanItemAssignmentVariant,
  ) {
    this.ispItemForm.controls.tasks.controls.forEach((taskForm: any) => {
      const assignmentByTaskType = variant!.tasks.find(
        (task) => task.type === taskForm.value.type,
      );
      (taskForm as any).controls.assignment.patchValue(
        assignmentByTaskType ?? {
          attachments: [],
          description: null,
        },
      );
    });
  }

  private readonly variantSelectionEffect = effect(() => {
    const currentVariantId = this.currentVariantId();
    const variantOptions = this.itemAssignmentVariants();
    const currentVariantValue = variantOptions.find(
      (variant) => variant.id === currentVariantId,
    );

    if (!currentVariantValue) {
      return;
    }

    this.applyVariantToIspItemForm(currentVariantValue);
  });

  protected onSubmit() {
    let call: Observable<any> | undefined = of(null);
    if (
      !this.dialogData().action ||
      this.dialogData().action === IspTaskDialogAction.EditAssignment
    ) {
      if (!this.ispItemForm.validate()) {
        this.message.invalidForm();
        return;
      }

      const formValue = this.ispItemForm.getRawValue();
      const tasks = this.isInterviewEnabled()
        ? formValue.tasks
        : formValue.tasks.filter(
            (task) => task.type !== IndividualStudyPlanItemTaskType.Interview,
          );
      const payload = {
        ...formValue,
        tasks,
      };

      if (this.dialogData().createItem) {
        call = this.ispService.addIndividualStudyPlanItem(payload as any);
      } else {
        call = this.ispService.updateIndividualStudyPlanItem(payload as any);
      }
    } else {
      const getActionCall = (
        form: UisFormGroup<any>,
        call: (
          itemId: string,
          request: any,
        ) => Observable<IndividualStudyPlanItem>,
      ) => {
        if (!form.validate()) {
          this.message.invalidForm();
          return;
        }
        const { itemId, ...request } = form.getRawValue();

        return call.bind(this.ispService)(itemId!, request as any);
      };

      switch (this.dialogData().action) {
        case IspTaskDialogAction.SubmitAnswer: {
          call = getActionCall(
            this.submitAnswerForm,
            this.ispService.submitIndividualStudyPlanItemTaskAnswer,
          );
          break;
        }
        case IspTaskDialogAction.Reject: {
          const ispItemFormValue = this.ispItemForm.getRawValue();

          const request: IndividualStudyPlanItemRejectRequest = {
            rejectReason: ispItemFormValue.rejectReason as any,
            currentAssignmentVariantId:
              ispItemFormValue.currentAssignmentVariantId as any,
            tasks: ispItemFormValue.tasks as any,
          };

          this.ispItemForm.validate();
          if (
            !(
              this.ispItemForm.controls.tasks.valid &&
              this.ispItemForm.controls.rejectReason.valid
            )
          ) {
            this.message.invalidForm();
            call = undefined;
            break;
          }

          call = this.ispService.rejectIndividualStudyPlanItem(
            ispItemFormValue.id!,
            request,
          );
          break;
        }
        case IspTaskDialogAction.RequestCorrection: {
          call = getActionCall(
            this.correctionRequestForm,
            this.ispService.requestCorrectionForIndividualStudyPlanItemTask,
          );
          break;
        }
        case IspTaskDialogAction.EditMark:
        case IspTaskDialogAction.Evaluate: {
          call = getActionCall(
            this.assessmentForm,
            this.ispService.assessIndividualStudyPlanItemTask,
          );
          break;
        }
        case IspTaskDialogAction.UpdateInterviewDate: {
          call = getActionCall(
            this.interviewTimeForm,
            this.ispService.updateInterviewDateForIndividualStudyPlanItem,
          );
          break;
        }
        default:
          call = of(null);
          break;
      }
    }

    if (!call) {
      return;
    }
    call.subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  onDelete() {
    this.dialogService
      .openDefault({
        text: `Видалити роботу ${IndividualStudyPlanItemTypeText[this.item()!.type!]} з предмету ${this.item()?.subjectData.subject?.name}?\n\nЦю дію неможливо скасувати.`,
        rejectText: 'Ні, залишити',
        confirmText: 'Так, видалити',
        confirmButtonStyle: {
          color: 'danger',
          fill: 'full',
        },
        primaryButton: 'reject',
        size: 'small',
      })
      .pipe(
        switchMap((res) =>
          res
            ? this.ispService
                .deleteIndividualStudyPlanItem(this.item()!.id)
                .pipe(map(() => true))
            : of(false),
        ),
      )
      .subscribe((res) => {
        this.dialogRef.close(res);
      });
  }

  protected readonly IspTaskDialogAction = IspTaskDialogAction;
}
